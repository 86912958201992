// Microsoft Edge
@supports (-ms-accelerator: true) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

// IE11
@media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}
